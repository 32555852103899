const defaultViewAngleId = 'overview'

// Sizes on a screen with width 1920px. Update if changing the layout or sizing of the images via styling.
const approximatePreviewSizesOnLargeDesktops = {
  front: { width: 1440, height: 400 },
  barrelEnd: { width: 560, height: 420 },
  knob: { width: 840, height: 440 },
}

const multiplier = 2
const getImageSizeWithMultiplierForHdScreens = (imageSize) => ({
  width: Math.round(imageSize.width * multiplier),
  height: Math.round(imageSize.height * multiplier),
})

const viewAngles = {
  overview: {
    label: 'Overview',
    exposeForUI: true,
  },
  barrel: {
    label: 'Barrel',
    exposeForUI: true,
  },
  handle: {
    label: 'Handle',
    exposeForUI: true,
  },
  end: {
    label: 'End',
    exposeForUI: true,
  },
  knob: {
    label: 'Knob',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: getImageSizeWithMultiplierForHdScreens(
      approximatePreviewSizesOnLargeDesktops.knob,
    ),
  },
  front: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: getImageSizeWithMultiplierForHdScreens(
      approximatePreviewSizesOnLargeDesktops.front,
    ),
  },
  barrelEnd: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: getImageSizeWithMultiplierForHdScreens(
      approximatePreviewSizesOnLargeDesktops.barrelEnd,
    ),
  },
}

export default viewAngles
export { defaultViewAngleId }
